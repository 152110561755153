import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Grid, useBreakpointValue, Button, Tooltip } from "@chakra-ui/react";
import "../../styles/home.css";
import { motion } from "framer-motion";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import Header from "../../components/Properties/header";
import PropertyTypes from "../../components/Properties/PropertyTypes";
import { useDispatch } from "react-redux";
import PropertyCard from "../Properties/PropertyCard";
import { SkeletonCard } from "../common/Skeleton";
import { FormattedMessage } from "react-intl";

const initialStateData = {
  title_en: '',
  propertyType: '',
  propertyPurpose: '',
  bedroom: '',
  bathroom: '',
  minSize: '',
  maxSize: '',
  community: '',
  completion_status: '',
};

const MotionButton = motion(Button);

const Property = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("sale");
  const [stateData, setStateData] = useState(initialStateData);
  const take = useBreakpointValue({ base: 3, md: 16 }) || 8;

  const memoizedQueryKey = useMemo(() => ["PropertyInfinite", selectedType, take], [selectedType, take]);

  const { 
    data: propertyPages, 
    isLoading, 
    isFetchingNextPage, 
    hasNextPage, 
    fetchNextPage 
  } = useInfiniteQuery({
    queryKey: memoizedQueryKey,
    queryFn: async ({ pageParam = 0 }) => {
      const response = await fetchPropertyList({ 
        take, 
        skip: pageParam, 
        property_purpose: selectedType 
      });
      return response?.data || {};
    },
    getNextPageParam: (lastPage, pages) => {
      const { records = [] } = lastPage;
      if (records.length < take) return undefined;
      return pages.reduce((sum, page) => sum + (page.records?.length || 0), 0);
    },
    keepPreviousData: true,
  });

  const allRecords = useMemo(() => {
    return propertyPages?.pages?.flatMap((page) => page.records || []) || [];
  }, [propertyPages?.pages]);
  const totalCount = propertyPages?.pages?.[0]?.count || 0;
  const remaining = totalCount - allRecords.length;

  const auth = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem("userData"));
    } catch {
      return null;
    }
  }, []);
  const favoriteQueryKey = useMemo(() => ["PropertyLikes"], []);

  const handleSelectType = useCallback((type) => {
    setSelectedType(type);
  }, []);
  
  const getMinDistanceToSameTitle = (array, position, title) => {
  const minDesiredDistance = Math.ceil(array.length / 1); 
  let minDistance = array.length;
  
  for (let i = 0; i < array.length; i++) {
    if (array[i] && array[i].title_en === title) {
      const distance = Math.min(
        Math.abs(position - i),
        array.length - Math.abs(position - i)
      );
      minDistance = Math.min(minDistance, distance);
    }
  }
  
  return minDistance < minDesiredDistance ? 0 : minDistance;
};
  
  const shuffledRecords = useMemo(() => {
    if (!allRecords || !Array.isArray(allRecords)) return [];
    const propertyGroups = new Map();
    
    allRecords.forEach((property) => {
      const key = property.title_en;
      if (!propertyGroups.has(key)) {
        propertyGroups.set(key, [property]);
      } else {
        propertyGroups.get(key).push(property);
      }
    });
  
    const totalLength = allRecords.length;
    const result = new Array(totalLength);
    let currentIndex = 0;
  
    propertyGroups.forEach((group) => {
      if (currentIndex < totalLength) {
        result[currentIndex] = group[0];
        currentIndex++;
      }
    });

    propertyGroups.forEach((group) => {
      if (group.length > 1) {
        for (let i = 1; i < group.length; i++) {
          let bestPosition = currentIndex;
          let maxDistance = 0;
  
          for (let j = currentIndex; j < totalLength; j++) {
            if (!result[j]) {
              const distance = getMinDistanceToSameTitle(result, j, group[0].title_en);
              if (distance > maxDistance) {
                maxDistance = distance;
                bestPosition = j;
              }
            }
          }
  
          result[bestPosition] = group[i];
          currentIndex = (bestPosition + 1) % totalLength;
        }
      }
    });
  
    return result.filter(Boolean);
  }, [allRecords]);

  const handleViewAllClick = useCallback(() => {
    const queryParams = {
      ...stateData,
      skip: 0,
      take: 6,
      currentPage: 1,
      property_purpose: selectedType,
      propertyType: stateData.propertyType,
    };
    dispatch(setListingQueryParams(queryParams));

    navigate('/properties', {
      state: {
        ...queryParams,
        selectedTab: selectedType === 'sale' ? 'Buy' : selectedType === 'rent' ? 'Rent' : selectedType,
      },
    });
  }, [dispatch, navigate, selectedType, stateData]);

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: favoriteQueryKey,
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box backgroundColor="white" pt={20} pb={40} px={{ base: 3, md: 6 }}>
    <Box maxWidth="1470px" mx="auto">
      <Flex justifyContent="space-between" flexDirection={{ base: "column", md: "row" }} alignItems="center" mb={8}>
        <Header />
        <PropertyTypes 
        selectedType={selectedType} 
        onSelectType={handleSelectType}
        />
      </Flex>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: "repeat(1, 1fr)", md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)', '2xl': 'repeat(4, 1fr)' }} gap={6} maxWidth="100%">
        {(isLoading || favoriteLoading)
            ? Array.from({ length: take }).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            : shuffledRecords?.map((item) => {
                const likeArr = favorite?.filter(
                  (like) => like?.propertyId === item?.id
                );
                return (
                  <Box key={item?.id}>
                    <PropertyCard item={item} liked={likeArr} refetch={refetch} />
                  </Box>
                );
              })}
        </Grid>

        {hasNextPage && (
          <Flex justify="center" my={4}>
            <Tooltip 
              label={`${remaining} more properties to show`} 
              aria-label="Show more info"
              placement="top"
              fontSize="lg"
              py={4}
              hasArrow
            >
              <MotionButton
                onClick={() => fetchNextPage()}
                colorScheme="yellow"
                disabled={isFetchingNextPage}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 15px rgba(229, 190, 84, 0.7)",
                }}
                whileTap={{ scale: 0.95 }}
                isLoading={isFetchingNextPage}
                loadingText="Loading"
                spinnerPlacement="end"
                size={buttonSize}
                px={6}
                py={6}
                fontSize="lg"
                boxShadow="md"
                transition="all 0.1s"
              >
                <FormattedMessage id="view_more" defaultMessage="Show More" />
              </MotionButton>
            </Tooltip>
          </Flex>
        )}
    </Box>
  </Box>
);
};

export default Property;
