import React, { useRef } from "react";
import { Box, Flex } from "@chakra-ui/react";
import BlogHeader from "./Header";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../../utils/axiosMiddleware";
import { bucket_base_url } from "../../../constants/constant";
import { base_url } from "../../../constants/constant";
import BlogItem from "./BlogItem";
import Carousel from "../../common/Carousal";

export default function Blogs() {
  const carouselRef = useRef();

  const { data: blogData } = useQuery({
    queryFn: () => Axios.get(`${base_url}/blogs`),
    select: (res) => res?.data?.records,
  });

  return (
    <Box bg="white">
    <Box maxWidth="1470px" mx="auto" py={[6, 8, 12]} px={{ base: 9, md: 0 }} pb={{base: "50px", md: "40px"}}>
      <Box mb={[6, 8, 10]} textAlign={["left", "left"]}>
        <BlogHeader />
      </Box>
      <Flex
        justifyContent="center" 
        alignItems="center"
        position="relative" 
        mx={{base: 0, md: "auto"}}
      >
      <Carousel ref={carouselRef} cardWidth={370} cardMargin={0}>
        {blogData?.map((item) => (
          <BlogItem
            key={item?.id}
            id={item?.id}
            item={item}
            title={item?.title}
            description={item?.content}
            imageSrc={`${bucket_base_url}${item?.file[0]?.filePath}`}
            createdBy={item?.user?.name}
            category={item?.category}
          />
        ))}
      </Carousel>
      </Flex>
    </Box>
    </Box>
  );
}
