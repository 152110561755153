import { Box } from "@chakra-ui/react";
import GoogleRatingsWithProvider from "./GoogleRatingsProvider";
import Header from "./Header";

const GoogleRatingsAndReviews = () => {
    return (
      <Box bg="gray.50">
      <Box maxWidth="1470px" mx="auto" px={{ base: 8, md: 0 }} py={[6, 8, 12]} pb={{base: "50px", md: "40px"}}>
        <Box mb={[6, 8, 10]} pl={{ base: 2, md: 0 }} textAlign={["left", "left"]}>
        <Header />
        </Box>
        <GoogleRatingsWithProvider />
      </Box>
      </Box>
    );
  };
  
  export default GoogleRatingsAndReviews;