import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Text, Spinner } from "@chakra-ui/react";
import { fetchLikeProperty } from "../../api/Property/propertyApi";
import { QueryClient } from "@tanstack/react-query";
import { PropertyCard } from "./Card";
import { FormattedMessage } from "react-intl";

function SavedProperties({ refetchSavedProperties }) {
  const [dislikeTrigger, setDislikeTrigger] = useState(false);
  const [localFavorites, setLocalFavorites] = useState([]);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const queryClient = new QueryClient();

  const { data: favorite, isLoading, refetch } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => fetchLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  useEffect(() => {
    if (favorite) {
      setLocalFavorites(favorite);
    }
  }, [favorite]);

  const handleDislikeSuccess = (itemId) => {
    setLocalFavorites(prev => prev.filter(f => f.id !== itemId));
    queryClient.invalidateQueries(["PropertyLikes"]);
  }

  useEffect(() => {
    if (dislikeTrigger) {
      refetch();
      setDislikeTrigger(false); 
    }
  }, [dislikeTrigger, refetch]);

  return (
    <Box p={{ base: 2, md: 4 }}>
      <Text 
        fontSize="xl" 
        fontWeight="bold" 
        pt={{ base: 5, md: 10 }}
        textAlign={{ base: "center", md: "left" }}
      >
        <FormattedMessage id="savedProperties.title" defaultMessage="Saved Properties" />
      </Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid 
          templateColumns="1fr"
          gap={{ base: 6, md: 4 }}
          mt={{ base: 2, md: 4 }}
        >
          {localFavorites?.map((item) => (
            <PropertyCard
              key={item.id}
              item={item.Property}
              liked={[{ id: item.id }]}
              refetch={refetch}  
              refetchSavedProperties={refetchSavedProperties}
              handleDislikeSuccess={handleDislikeSuccess}
              setDislikeTrigger={setDislikeTrigger}
              isSavedProperties={true}
            />
          ))}
        </Grid>
      )}
      {localFavorites?.length === 0 && !isLoading && (
        <Text>
          <FormattedMessage id="savedProperties.noSaved" defaultMessage="No saved properties found." />
        </Text>
      )}
    </Box>
  );
}

export default SavedProperties;
