import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import { Row, Col } from "antd";
import HomeContectUs from "../../components/Home/HomeContectUs";
import "../../styles/about.css";
import { VisionMission } from "../../components/AboutUs/VisionMission";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";

const AboutUs = () => {
  return (
    <>
      <Header />
      <Row>
      <Col xs={24} className="Container-top">
          <LazyLoadImage
            src="/icons/about.webp"
            alt="About Us"
            effect="blur"
            className="Container-top-image"
          />
          <div className="Container-content">
            <span className="heading-aboutUs">
              <FormattedMessage id="about_us" />
            </span>
            <span className="sub-heading">
              <FormattedMessage id="about_us_welcome" />
            </span>
          </div>
        </Col>
      </Row>
      <VisionMission
        imageClass="first-image"
        contentClass="second-image"
        heading={<FormattedMessage id="our_vision" />}
        text={<FormattedMessage id="vision_text" />}
        headingClass="heading-text"
        textClass="Div-text"
      />
      <VisionMission
        imageClass="first-image1"
        contentClass="second-image2"
        heading={<FormattedMessage id="our_mission" />}
        text={<FormattedMessage id="mission_text" />}
        headingClass="heading-text1"
        textClass="Div-text1"
      />
      <HomeContectUs />
    </>
  );
};

export default AboutUs;
