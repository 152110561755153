import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

export const MapContext = React.createContext(null);

const MapProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places", "maps"],
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <MapContext.Provider value={{ isLoaded }}>
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
