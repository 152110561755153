import React, { useRef } from "react";
import { Box, Text, Flex, Avatar } from "@chakra-ui/react";
import Header from "./Header";
import { useQuery } from "@tanstack/react-query";
import Carousel from "../common/Carousal"; 
import { Axios } from "../../utils/axiosMiddleware";
import { bucket_base_url } from "../../constants/constant";

export default function Testimonial() {
  const carouselRef = useRef();

  const { data: testimonials } = useQuery({
    queryKey: ["testimonial"],
    queryFn: () => Axios.get("/testimonials"),
    select: (res) => res?.data?.records,
  });

  return (
    <Box bg="gray.50">
    <Box maxWidth="1470px" mx="auto" py={[6, 8, 12]} px={{ base: 8, md: 0 }} pb={{base: "50px", md: "40px"}}>
      <Box mb={[6, 8, 10]} pl={{ base: 2, md: 0 }}  textAlign={["left", "left"]}>
        <Header />
      </Box>
      {testimonials?.length > 0 && (
        <Flex
          justifyContent="center" 
          alignItems="center"
          position="relative"
          mx="auto"
        >
          <Carousel cardWidth={370} cardMargin={0} ref={carouselRef}>
          {testimonials?.map((item) => (
            <Box
              key={item?.id}
              bg="white"
              p={[4, 5, 6]}
              mx={{ base: 0, md: 3 }}
              borderRadius="20px"
              boxShadow="lg"
              maxW={{ base:"320px", md: "400px" }}
              minH="410px"
              textAlign="left"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Box mb={4} h="60px" display="flex" alignItems="center">
                  <Text fontSize="2xl" fontWeight="bold" noOfLines={2}>
                    {item?.reviewTitle}
                  </Text>
                </Box>
                <Text mb={4} fontSize="md" fontWeight="bold">
                  "{item?.reviewText}"
                </Text>
              </Box>
              <Flex
                alignItems="center"
                mt="auto"
                pt={4}
                borderTop="1px solid"
                borderColor="gray.200"
              >
                <Avatar
                  src={`${bucket_base_url}${item?.file?.filePath}`}
                  alt={item?.clientName}
                  size="lg"
                  mr={4}
                />
                <Box>
                  <Flex alignItems="center">
                    <Text fontSize="md" fontWeight="bold" mr={2}>
                      {item?.clientName}
                    </Text>
                    <Flex>
                      {Array.from({ length: 5 }, (_, i) => (
                        <Box
                          as="span"
                          key={i}
                          color={i < item?.rating ? "yellow.400" : "gray.300"}
                          fontSize="2xl"
                          mr={1}
                        >
                          ★
                        </Box>
                      ))}
                    </Flex>
                  </Flex>
                  <Text fontSize="md" color="gray.500">
                    {item?.designation || "Client"}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ))}
        </Carousel>
      </Flex>
      )}
    </Box>
    </Box>
  );
}
