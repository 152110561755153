import { Row, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useDispatch } from "react-redux";
import "../../styles/footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = (propertyPurpose, propertyType) => {
    const tabMapping = {
      rent: "rent",
      sale: "sale",
      commercial: "commercial",
    };
  
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,
        property_purpose: propertyPurpose,
        property_type: propertyType,
      })
    );
  
    navigate(`/properties?tab=${tabMapping[propertyPurpose]}`, {
      state: { property_purpose: propertyPurpose, property_type: propertyType },
    });
  };
  
  return (
    <>
      <div className="footer-divider-container"></div>
      <Row className="footer-main-container">
        <Col xs={24} lg={12} className="footer-left">
          <div onClick={() => navigate("/")}>
            <img
              alt="logo"
              src="/icons/Logo Colored.svg"
              style={{ cursor: "pointer", width: '250px' }}
            />
          </div>
          <p className="live-support">
            <FormattedMessage id="footer.live_support" defaultMessage="Live Support" />
            <br />info@sandseekers.ae
          </p>
          <p className="customer-support">
            <FormattedMessage id="footer.customer_support" defaultMessage="Contact" />
            <br />02-6277156
          </p>
          <h3>
            <FormattedMessage id="footer.social_media_follow" defaultMessage="Follow us on social media" />
          </h3>
          <div className="social-media-links">
            <a href="https://www.facebook.com/profile.php?id=61560022119418"><img src="/icons/facebook.svg" alt="Facebook" className="social-icon" /></a>
            <a href="https://www.linkedin.com/company/sand-seekers-real-estate-llc/"><img src="/icons/linkedin.webp" alt="Linkedin" className="social-icon" /></a>
            <a href="https://www.instagram.com/sandseekersrealestatellc/"><img src="/icons/instagram.png" alt="Instagram" className="social-icon" /></a>
          </div>
        </Col>
        <Col xs={24} lg={12} className="footer-right">
          <div className="footer-listing-container">
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.popular_search" defaultMessage="Popular Search" />
              </span>
              <ul className="footer-list">
                <li className="footer-link" onClick={() => handleSearch("sale", "AP")}>
                  <FormattedMessage id="footer.apartment_for_sale" defaultMessage="Apartment for Sale" />
                </li>
                <li className="footer-link" onClick={() => handleSearch("rent", "AP")}>
                  <FormattedMessage id="footer.apartment_for_rent" defaultMessage="Apartment for Rent" />
                </li>
                <li className="footer-link" onClick={() => handleSearch("commercial", "OF")}>
                  <FormattedMessage id="footer.offices_for_rent" defaultMessage="Offices for Rent" />
                </li>
              </ul>
            </div>
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.quick_links" defaultMessage="Quick Links" />
              </span>
              <ul className="footer-list">
                <li className="footer-link" onClick={() => navigate("/terms-of-conditions")}><FormattedMessage id="footer.terms_of_services" defaultMessage="Terms of Services" /></li>
                <li className="footer-link" onClick={() => navigate("/privacy-policy")}><FormattedMessage id="footer.privacy_policy" defaultMessage="Privacy Policy" /></li>
                <li className="footer-link" onClick={() => navigate("/contact-us")}><FormattedMessage id="footer.contact" defaultMessage="Contact" /></li>
              </ul>
            </div>
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.discovery" defaultMessage="Discovery" />
              </span>
              <ul className="footer-list">
                <li className="footer-link" onClick={() => navigate("/", { state: { scrollTo: "ExploreTowns" } })}>
                  <FormattedMessage id="footer.abu_dhabi" defaultMessage="Abu Dhabi" />
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
