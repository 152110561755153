/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Button } from "antd";
import "../../styles/home.css";
import Property from "../../components/Home/Property";
import Feature from "../../components/Home/Feature";
import HomeContectUs from "../../components/Home/HomeContectUs";
import ExploreApartments from "../../components/PropertyTypeSection";
import { IconButton, Flex } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import ExploreTowns from "../../components/ExploreTowns";
import Blogs from "../../components/Home/Blogs";
import GoogleRatingsAndReviews from "../../components/GoogleReviews";
import CallToAction from "../../components/Home/CallToAction";
import { useLocation } from "react-router-dom";
import { throttle } from 'lodash';
import {
  HandleArabicClick,
  HandleEnglishClick,
} from "../../utils/Helper";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import Testimonial from "../../components/Testimonials/Testimonial";
import SearchBar from "../../components/Home/SearchBar";

const Home = () => {
  const location = useLocation();
  const exploreTownsRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    if (location.state?.scrollTo === "ExploreTowns" && exploreTownsRef.current) {
      exploreTownsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

  useEffect(() => {
      const throttledHandleScroll = throttle(() => {
        if (window.scrollY > 200) {
          setShowScrollToTop(true);
        } else {
          setShowScrollToTop(false);
        }
      }, 200);
    
      window.addEventListener("scroll", throttledHandleScroll);
      return () => {
        window.removeEventListener("scroll", throttledHandleScroll);
        throttledHandleScroll.cancel();
      };
    }, []);

  let Language;
  const lang = localStorage.getItem("lang");
  if (lang) {
    Language = "ar";
  } else {
    Language = "en";
  }
  return (
    <div>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="home-section-1">
        <div className="language_button_position">
          <Button.Group>
            <Button
              className={Language === "en" ? "enClass" : "arClass"}
              onClick={HandleEnglishClick}
            >
              English
            </Button>
            <Button
              className={Language === "ar" ? "enClass" : "arClass"}
              onClick={HandleArabicClick}
            >
              عربي
            </Button>
          </Button.Group>
        </div>

        <div className="home-heading-container">
          <p>
            <FormattedMessage id="find_your_dream" />
          </p>
        </div>
        <div className="home-paragraph-container">
          <p>
            <FormattedMessage id="home_welcome" />
          </p>
        </div>

        <SearchBar />
      </div>

      <Property />

      <ExploreApartments />

      <div style={{padding: 0}} ref={exploreTownsRef}>
        <ExploreTowns />
      </div>

      <CallToAction />

      <Feature />

      <Testimonial />

      <Blogs />

      <GoogleRatingsAndReviews /> 

      <HomeContectUs />

      {showScrollToTop && (
        <Flex
          position="fixed"
          bottom="30px"
          right="30px"
          zIndex="1000"
          align="center"
          justify="center"
        >
          <IconButton
            icon={<ArrowUpIcon />}
            onClick={scrollToTop}
            colorScheme="yellow"
            aria-label="Scroll to top"
          />
        </Flex>
      )}
    </div>
  );
};

export default Home;
