import React from "react";
import Header from "../Header";
import { FormattedMessage } from "react-intl";
import { BlogListing } from "./blog-listing";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Blog = () => {
  return (
    <div>
      <Header />
      <div className="blog-section">
        <LazyLoadImage
          src="/icons/blogs.webp"
          alt="Blogs"
          effect="blur"
          className="blog-section-image"
        />
        <div className="blog-section-content">
          <h1 className="blog-page-top">
            <FormattedMessage id="Blogs" />
          </h1>
          <p className="blog-top-subheading">
            <FormattedMessage id="stay_ahead" />
          </p>
        </div>
      </div>
      <BlogListing />
    </div>
  );
};
