import React from "react";
import MapProvider from "../../common/MapProvider";
import { GoogleRatings } from "../RatingsAndReviews";

const GoogleRatingsWithProvider = () => (
  <MapProvider>
    <GoogleRatings />
  </MapProvider>
);

export default GoogleRatingsWithProvider;