export const timeFrame = [
    { key: "Now", value: "Now" },
    { key: "1 Month", value: "1 Month" },
    { key: "2 Months", value: "2 Months" },
    { key: "3 Months", value: "3 Months" },
    { key: "1-3 Months", value: "1-3 Months" },
    { key: "3-6 Months", value: "3-6 Months" },
    { key: "6-9 Months", value: "6-9 Months" },
    { key: "9-12 Months", value: "9-12 Months" },
];  

export const status = [
    { key: "New", value: "New" },
    { key: "Prospect", value: "Prospect" },
    { key: "Visit in Progress", value: "visit_in_progress" },
    { key: "Qualified", value: "qualified" },
    { key: "Contract Sent", value: "contract_sent" },
    { key: "Contract Signed", value: "contract_signed" },
    { key: "Not Ready", value: "not_ready" },
    { key: "Not Interested", value: "not_interested" },
    { key: "Called No Reply", value: "called_no_reply" },
];

export const propertyType = [
    { key: "property_type.apartment", value: "AP" }, 
    { key: "property_type.duplex", value: "DX" }, 
    { key: "property_type.penthouse", value: "PH" }, 
    { key: "property_type.townhouse", value: "TH" },
    { key: "property_type.villa", value: "VH" }, 
    { key: "property_type.short_term_hotel_apartment", value: "SH" },
    { key: "property_type.office_space", value: "OF" },
    { key: "property_type.show_room", value: "SR" },
  ];
  
  export const locationOption = [
    { community: 'location.mohamed_bin_zayed_city' },
    { community: 'location.al_reem_island' },
    { community: 'location.al_raha_beach' },
    { community: 'location.mussafah' },
    { community: 'location.sas_al_nakheel' },
    { community: 'location.al_bateen' },
    { community: 'location.masdar_city' },
    { community: 'location.tourist_club_area' },
    { community: 'location.saadiyat_island' },
    { community: 'location.khalifa_city' },
    { community: 'location.al_khalidiya' },
    { community: 'location.corniche_road' },
    { community: 'location.electra_street' },
    { community: 'location.the_marina' },
    { community: 'location.yas_island' },
    { community: 'location.grand_mosque_district' },
    { community: 'location.hamdan_street' },
    { community: 'location.eastern_road' },
  ];

export const source = [
    { key: "Property Finder", value: "Property Finder" }, 
    { key: "Portal", value: "Portal" }, 
    { key: "Social Media", value: "Social Media" }, 
    { key: "Online", value: "Online" }, 
    { key: "Offline", value: "Offline" },
    { key: "Other", value: "Other" }, 
];  

export const DEFAULT_PARAMS = {
  take: 6,
  skip: 0,
  minPrice: 0,
  maxPrice: 20000000,
  minArea: 0,
  maxArea: 200000,
};

export const imageCache = new Map();

export const CHUNK_SIZE = 12;
