import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  breakpoints: {
    xs: "320px",
    sm: "480px", 
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    "2xl": "1536px"
  }
});

export default customTheme;
