import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Spin, Form } from "antd";
import { IconButton, Flex, Button, Tooltip } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { filterQueryParams } from "../../utils/Helper";
import { throttle } from 'lodash';
import "../../styles/properties.css";
import Header from "../Header";
import "../../styles/home.css";
import PropertyCard from "./PropertyCard";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useLocation } from "react-router-dom";
import { DEFAULT_PARAMS, CHUNK_SIZE } from "../../utils/constant";
import SearchBar from "../Home/SearchBar";

const Properties = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const { listingQueryParams } = useSelector((state) => state?.property);
  const [selectedType, setSelectedType] = useState("sale");
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const MotionButton = motion(Button);

  useEffect(() => {
    if (!listingQueryParams?.take) {
      dispatch(setListingQueryParams(DEFAULT_PARAMS));
    }
  }, [dispatch, listingQueryParams]);

  useEffect(() => {
    return () => {
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const propertyPurpose = queryParams.get("property_purpose");
    
    if (propertyPurpose) {
      setSelectedType(propertyPurpose);
    }
  }, [location.search]);

  const handleTableChange = 
    (current) => {
      dispatch(
        setListingQueryParams({
          ...listingQueryParams,
          currentPage: current, 
          skip: (current - 1) * (listingQueryParams.take || DEFAULT_PARAMS.take),
        })
      );
    };
  const { take, skip, ...searchParams } = useMemo(() => {
    let params = { ...listingQueryParams };

    if (params.bedroom <= 0 || params.bedroom === undefined) {
      delete params.bedroom;
    }
    if (params.bathroom <= 0 || params.bathroom === undefined) {
      delete params.bathroom;
    }

    params = filterQueryParams({
      params,
      excludedParams: ["currentPage"],
    });

    return { ...params };
  }, [listingQueryParams]);

  const {
    data: propertyPages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["PropertyInfinite", searchParams],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await fetchPropertyList({
        ...searchParams,
        skip: pageParam,
        take: CHUNK_SIZE,
      });
      return response?.data || {};
    },
    getNextPageParam: (lastPage, pages) => {
      const { records = [] } = lastPage;
      if (records.length < CHUNK_SIZE) {
        return undefined;
      }
      const totalLoaded = pages.reduce(
        (sum, page) => sum + (page.records?.length || 0),
        0
      );
      return totalLoaded;
    },
    keepPreviousData: true,
  });

  const allRecords = useMemo(() => {
    return propertyPages?.pages?.flatMap((page) => page.records || []) || [];
  }, [propertyPages?.pages]);

  const totalCount = propertyPages?.pages?.[0]?.count || 0;
  const remaining = totalCount - allRecords.length;

  const getMinDistanceToSameTitle = (array, position, title) => {
    const minDesiredDistance = Math.ceil(array.length / 1); 
    let minDistance = array.length;
    
    for (let i = 0; i < array.length; i++) {
      if (array[i] && array[i].title_en === title) {
        const distance = Math.min(
          Math.abs(position - i),
          array.length - Math.abs(position - i)
        );
        minDistance = Math.min(minDistance, distance);
      }
    }
    
    return minDistance < minDesiredDistance ? 0 : minDistance;
  };

 const shuffledRecords = useMemo(() => {
     if (!allRecords || !Array.isArray(allRecords)) return [];
     const propertyGroups = new Map();
     
     allRecords.forEach((property) => {
       const key = property.title_en;
       if (!propertyGroups.has(key)) {
         propertyGroups.set(key, [property]);
       } else {
         propertyGroups.get(key).push(property);
       }
     });
   
     const totalLength = allRecords.length;
     const result = new Array(totalLength);
     let currentIndex = 0;
   
     propertyGroups.forEach((group) => {
       if (currentIndex < totalLength) {
         result[currentIndex] = group[0];
         currentIndex++;
       }
     });
 
     propertyGroups.forEach((group) => {
       if (group.length > 1) {
         for (let i = 1; i < group.length; i++) {
           let bestPosition = currentIndex;
           let maxDistance = 0;
   
           for (let j = currentIndex; j < totalLength; j++) {
             if (!result[j]) {
               const distance = getMinDistanceToSameTitle(result, j, group[0].title_en);
               if (distance > maxDistance) {
                 maxDistance = distance;
                 bestPosition = j;
               }
             }
           }
   
           result[bestPosition] = group[i];
           currentIndex = (bestPosition + 1) % totalLength;
         }
       }
     });
   
     return result.filter(Boolean);
   }, [allRecords]);

  useEffect(() => {
    refetch();
}, [refetch, listingQueryParams]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const throttledHandleScroll = throttle(() => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }, 200);
  
    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, []);

  useEffect(() => {
    if (listingQueryParams?.property_type || listingQueryParams?.community) {
      form?.setFieldsValue({
        property_type: listingQueryParams?.property_type,
        community: listingQueryParams?.community,
      });
    } else {
      form.resetFields();
    }

    return () => form.resetFields();
  }, [listingQueryParams, form]);

  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    refetch: likeRefetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  return (
    <>
      <div className="properties-main-image-container">
        <Header />
        <div className="home-heading-container">
          <p>
            <FormattedMessage id="properties" />
          </p>
        </div>
        <div className="home-paragraph-container">
          <p>
            <FormattedMessage id="discover_perfect" />
          </p>
        </div>
        <SearchBar />
      </div>
      {isLoading && allRecords.length === 0 ? (
        <div className="Loader-div">
          <Spin
            spinning={!!isLoading}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                  color: "#e5be54",
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <div style={{ marginTop: "70px" }}>
          <Row justify={"center"}>
          {shuffledRecords?.map((item) => {
              const likeArr = favorite?.filter(
                (like) => like?.propertyId === item?.id
              );
              return (
                <Col
                  xs={24}
                  md={12}
                  xl={8}
                  xxl={7}
                  className="col-alignment"
                  key={item?.id}
                >
                  <PropertyCard
                    item={item}
                    liked={likeArr}
                    refetch={likeRefetch}
                  />
                </Col>
              );
            })}

            {allRecords?.length === 0 && !isLoading && (
              <span className="no_data_found">
                <FormattedMessage id="no_properties_found" />
              </span>
            )}
          </Row>

          {hasNextPage && (
          <Flex justify="center" my={4}>
            <Tooltip 
              label={`${remaining} more properties to show`} 
              aria-label="Show more info"
              placement="top"
              fontSize="lg"
              py={4}
              hasArrow
            >
            <MotionButton
              onClick={() => fetchNextPage()}
              colorScheme="yellow"
              disabled={isFetchingNextPage}
              whileHover={{
                scale: 1.01,
                boxShadow: "0px 0px 15px rgba(229, 190, 84, 0.7)",
              }}
              whileTap={{ scale: 0.95 }}
              isLoading={isFetchingNextPage}
              loadingText="Loading"
              spinnerPlacement="end"
              px={6}
              py={6}
              fontSize="lg"
              boxShadow="md"
              transition="all 0.1s"
            >
              Show More
            </MotionButton>
            </Tooltip>
          </Flex>
        )}
        </div>
      )}
      {showScrollToTop && (
        <Flex
          position="fixed"
          bottom="30px"
          right="30px"
          zIndex="1000"
          align="center"
          justify="center"
        >
          <Tooltip label="Scroll to Top" placement="left" hasArrow>
          <IconButton
            icon={<ArrowUpIcon />}
            onClick={scrollToTop}
            colorScheme="yellow"
            aria-label="Scroll to top"
          />
          </Tooltip>
        </Flex>
      )}
    </>
  );
};

export default Properties;
