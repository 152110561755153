import React, { useMemo, Suspense } from 'react';
import { Box, Image, Heading, Text, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom'; 
import { fetchPropertyList } from '../../../api/Property/propertyApi';
import Carousel from '../../common/Carousal'; 

const PropertyGrid = ({ selectedType }) => {
  const navigate = useNavigate(); 

  const queryParams = useMemo(() => ({
    property_type: selectedType || 'Offices',                          
  }), [selectedType]);

  const { data: propertyList, isLoading, isError, error } = useQuery({
    queryKey: ['PropertyList', queryParams],
    queryFn: () => fetchPropertyList(queryParams),
    select: (res) => res?.data,
    keepPreviousData: true,
  });

  const filteredProperties = useMemo(() => propertyList?.records?.slice(0, 50) || [], [propertyList]);

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (isError) {
    return <Text>Error loading properties: {error?.message}</Text>;
  }

  if (!filteredProperties || filteredProperties?.length === 0) {
    return <Text>No properties found for the selected filter.</Text>;
  }

  const handlePropertyClick = (propertyId) => {
    navigate(`/property-details/${propertyId}`); 
  };

  return (
    <Box>
      <Suspense fallback={<Spinner size="xl" />}>
        <Carousel items={filteredProperties?.records || []} >
          {filteredProperties?.map((property) => (
            <Box
              key={property?.id}
              borderWidth="1px"
              borderRadius="15px"
              overflow="hidden"
              boxShadow="lg"
              backgroundColor="white"
              height={selectedType === 'OF' ? '400px' : '420px'}
              mr={{ base: 10, md: 0 }}
              width={{ base: "330px", md: "350px" }}
              onClick={() => handlePropertyClick(property?.id)} 
              cursor="pointer" 
            >
              <Image
                src={property?.file?.[0]?.filePath || 'https://via.placeholder.com/400'}
                alt={property?.title_en}
                objectFit="cover"
                height="200px"
                width="100%"
              />
              <Box p="6">
                <Heading as="h3" size="md" mb="2">
                  {property?.title_en}
                </Heading>
                <Text color="gray.500">Location: {property?.community}</Text>
                <Text color="gray.500">Price: AED {property?.price}</Text>
                {selectedType !== 'OF' && (
                  <Text color="gray.500">Bedrooms: {property?.bedroom}</Text>
                )}
                <Text color="gray.500">Bathrooms: {property?.bathroom}</Text>
                <Text color="gray.500">Size: {property?.size} sqft</Text>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Suspense>
    </Box>
  );
};

export default PropertyGrid;
