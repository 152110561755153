import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { base_url } from "../../constants/constant";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useDispatch } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import PropertyInfo from "./PropertyInfo";
import AgentInfo from "./AgentInfo";
import FullPageSkeleton from "./Skeleton";
import Header from "../Header/index";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { likeProperty, disLikeProperty, getLikeProperty } from "../../api/Property/propertyApi";
import { FaHeart } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import {
  Box,
  Stack,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";

export default function PropertyDetails({auth}) {
  const [property, setProperty] = useState();
  const [agent, setAgent] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [like, setLike] = useState(false);
  const [likeId, setLikeId] = useState(null);
  const queryClient = useQueryClient();

  const {
    data: userLikes,
    refetch: refetchUserLikes,
  } = useQuery({
    enabled: !!auth?.id,
    queryKey: ["getUserLikes", auth?.id],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records, 
  });

  const { mutate: mutateLike } = useMutation({
    mutationFn: (data) => likeProperty(data),
    onSuccess: () => {
      refetchUserLikes();
      queryClient.invalidateQueries(["PropertyLikesCount"]);
    },
    onError: (error) => {
      message.error(error?.response?.data?.message || "Failed to add like.");
    },
  });

  const { mutate: mutateDislike } = useMutation({
    mutationFn: (likeId) => disLikeProperty(likeId),
    onSuccess: () => {
      refetchUserLikes();
      queryClient.invalidateQueries(["PropertyLikesCount"]);
    },
    onError: (error) => {
      message.error(error?.response?.data?.message || "Failed to remove like.");
    },
  });

  const handleLike = useDebouncedCallback(() => {
    if (!auth) {
      dispatch(setLoginModalVisible(true));
      return;
    }
    if (like) {
      mutateDislike(likeId);
    } else {
      mutateLike({
        userId: auth?.id,
        propertyId: property?.id,
      });
    }
    setLike((prev) => !prev);
  }, 300);

  const fetchPropertyDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${base_url}/property-listings/${id}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setProperty(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch property details.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchAgentDetails = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${base_url}/users/${userId}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setAgent(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch agent details.");
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const currentSearchParams = new URLSearchParams(window.location.search);
      const skip = currentSearchParams.get("skip") || "0";
      const take = currentSearchParams.get("take") || "6";
      const currentPage = currentSearchParams.get("currentPage") || "1";

      dispatch(
        setListingQueryParams({
          skip: parseInt(skip, 10),
          take: parseInt(take, 10),
          currentPage: parseInt(currentPage, 10),
        })
      );
    };
  
    window.addEventListener("popstate", handlePopState);
  
    handlePopState();
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dispatch]);
  

  const handleBackClick = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,  
      })
    );
    navigate(-1);
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, [fetchPropertyDetails]);

  useEffect(() => {
    if (property?.userId) {
      fetchAgentDetails(property.userId);
    }
  }, [property, fetchAgentDetails]);

  useEffect(() => {
    if (userLikes && property?.id) {
      const found = userLikes.find((likeItem) => likeItem.propertyId === property.id);
      if (found) {
        setLike(true);
        setLikeId(found.id);
      } else {
        setLike(false);
        setLikeId(null);
      }
    }
  }, [userLikes, property]);

  return (
    <Box 
      px={{ base: 4, md: 8, lg: 20 }} 
      py={{ base: 4, md: 10 }}
      maxW="1600px"
      mx="auto"
    > 
    <div className="header-wrapper">
        <Header />
    </div>
    <Flex
        align="center"
        justify="space-between"
        pt={{ base: 20, md: 10 }}
        pb={{ base: 2, md: 0 }}
        mb={{ base: 0, md: 0 }}
      >
        <Flex align="center" cursor="pointer" onClick={handleBackClick}>
          <Image src="/icons/leftarrowb.png" alt="Back" h="20px" />
          <Text ml={2} fontSize={{ base: "sm", md: "lg" }}>
            <FormattedMessage id="back" />
          </Text>
        </Flex>

        <Flex mt={{ base: 2, md: 2 }} align="center" className="heart-icon-wrapper">
        <Text mr={2} fontSize={{ base: "sm", md: "lg" }}>
          {like ? (
            <FormattedMessage id="remove_from_favorites" />
            ) : (
            <FormattedMessage id="save" />
            )}
          </Text>
          <button
            className={`like-button ${like ? "liked" : ""}`}
            onClick={handleLike}
          >
            {like ? <FaHeart /> : <CiHeart />}
          </button>
        </Flex>
      </Flex>

      {loading ? (
        <FullPageSkeleton />
      ) : (
        property && (
          <Stack spacing={{ base: 6, md: 10 }}>
            <Box w="full">
              <ImageCarousel images={property?.file} />
            </Box>
            
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: 8, lg: 12 }}
              align="flex-start"
            >
              <Box flex={{ lg: "2" }}>
                <PropertyInfo property={property} navigate={navigate} />
              </Box>
              <Box 
                flex={{ lg: "1" }}
                w={{ base: "100%", lg: "auto" }}
                position="sticky"
                top="20px"
              >
                <AgentInfo agent={agent} property={property} auth={auth} />
              </Box>
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
}