import { Box, Text, Image, Spinner } from '@chakra-ui/react';

const TownPreview = ({ townName, numProperties, isLoading }) => {
  return (
    <Box
      boxShadow="lg"
      cursor="pointer"
    >
      <Image
        src="/icons/mapView.jpeg"
        alt={townName}
        width="100%"
        height="70%"
        objectFit="cover"
      />
      <Box textAlign="center" mt={3} p={3}>
        <Text fontWeight="bold">{townName}</Text>
        <Text fontSize="sm">
          {isLoading ? (
            <Spinner size="sm" color="#ECC94BE6" mr={2} />
          ) : (
            `${numProperties}`
          )}{" "}
          Properties
        </Text>
      </Box>
    </Box>
  );
};

export default TownPreview;
