import React from "react";
import { Box, Flex, Text, VStack, List, ListItem, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Box p={4}>
      <Flex
        align="center"
        mb={4}
        cursor="pointer"
        onClick={() => navigate(-1)}
        maxW="full"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          variant="ghost"
          size="md"
          mr={2}
        />
        <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="bold">
          <FormattedMessage id="back" />
        </Text>
      </Flex>

      <Box textAlign="center" mb={6}>
        <Text
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          fontWeight="bold"
        >
          <FormattedMessage id="privacy_policy" />
        </Text>
      </Box>

      <Box textAlign="center" mb={6}>
        <Text fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          <FormattedMessage id="last_update" />
        </Text>
      </Box>

      <VStack
        align="start"
        spacing={4}
        p={{ base: 2, md: 4, lg: 6 }}
        borderRadius="md"
        boxShadow="md"
        bg="gray.50"
      >
        <List spacing={6} w="full">
          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="we_take" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={2}>
              <FormattedMessage id="information_we" />
            </Text>
            <List pl={4} styleType="disc" spacing={2}>
              <ListItem fontSize={{ base: "sm", md: "md", lg: "lg" }}>
                <FormattedMessage id="contact_info" />
              </ListItem>
              <ListItem fontSize={{ base: "sm", md: "md", lg: "lg" }}>
                <FormattedMessage id="property_info" />
              </ListItem>
              <ListItem fontSize={{ base: "sm", md: "md", lg: "lg" }}>
                <FormattedMessage id="usage_info" />
              </ListItem>
            </List>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="we_may" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="we_take2" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="we_use" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="our_web" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="our_web1" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="we_reserve" />
            </Text>
          </ListItem>
        </List>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
