import React from "react";
import { Box, Text, Badge, Image, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { decodeHtmlEntities } from "../../../../utils/Helper";

const BlogItem = ({ item, title, description, imageSrc, CreatedBy, category }) => {
  const navigate = useNavigate();

  return (
    <Box
      bg="white"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      textAlign="left"
      maxW={{ base:"300px", md: "400px" }}
      minH="420px"
      maxH="420px"
      mx={{base: 0, md: 4}}
      flexShrink="0"
      position="relative" 
    >
      <Box position="relative">
        <Image src={imageSrc} alt={title} w="100%" h="200px" objectFit="cover" />
        <Badge
          position="absolute"
          bottom="12px"
          right="12px"
          bg="white"
          borderRadius="md"
          px={2}
          py={1}
          fontWeight="bold"
          color="gray.700"
        >
          <Text>{CreatedBy}</Text>
        </Badge>
      </Box>
      <Box p={4} pb="30px" position="relative"> 
        <Box mb={2} h="40px" display="flex" alignItems="center">
          <Text fontWeight="bold" fontSize="lg" noOfLines={2}>
            {title}
          </Text>
        </Box>
        <Text fontWeight="bold" fontSize="md">
          {category}
        </Text>
        <Flex
          alignItems="center"
          mt={2}
          noOfLines={2}
          justify="space-between"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            dangerouslySetInnerHTML={{
              __html: decodeHtmlEntities(description),
            }}
            h="35px"
          />

        <Box
          className="read-more" 
          position="absolute"
          top="140px"
          left="10px"
          onClick={() =>
            navigate("/blog-details", {
              state: {
                id: item,
              },
            })
          }
        >
          <FormattedMessage id="Read More" />
        </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default BlogItem;
