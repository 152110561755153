import React, { useState, useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import AccountMenu from "./menu-bar";
import { UserProfile } from "../UserProfile/index.js";
import SavedProperties from "./SavedProperties";
import SavedSearches from "./SavedSearches";
import { getLikeProperty } from "../../api/Property/propertyApi";
import { base_url } from "../../constants/constant.js";
import { fetchLeads } from "../../api/lead/leadApi.js";
import axios from "axios";
import ContactedProperties from "./ContactedProperties";

export const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState("personalInfo");
  const [savedSearchesCount, setSavedSearchesCount] = useState(0);
  const [loadingSavedSearches, setLoadingSavedSearches] = useState(false);

  const auth = JSON.parse(localStorage.getItem("userData"));
  const { data: favorite, savedLoading, refetch: refetchSavedProperties } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const { data: contacted, isLoading: contactedLoading } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["ContactedPropertiesCount"],
    queryFn: () => fetchLeads({ userId: auth?.id }),
    select: (res) => res?.data?.records,
  });

  useEffect(() => {
    const calculateSavedSearchesCount = async () => {
      setLoadingSavedSearches(true);
      const auth = JSON.parse(localStorage.getItem("userData"));
      const userId = auth?.id;
      const savedSearches = JSON.parse(localStorage.getItem(`savedSearches_${userId}`)) || [];
      let totalProperties = 0;

      await Promise.all(
        savedSearches.map(async (search) => {
          try {
            const response = await axios.get(`${base_url}/property-listings`, {
              params: search,
              headers: { "ngrok-skip-browser-warning": true },
            });
            totalProperties += Math.min(response.data.records.length, 3); 
          } catch (error) {
            console.error("Failed to fetch properties for saved search:", error);
          }
        })
      );

      setSavedSearchesCount(totalProperties);
      setLoadingSavedSearches(false);
    };

    calculateSavedSearchesCount();
}, []);


  const contactedPropertiesCount = contacted ? contacted.length : 0;

  const savedPropertiesCount = favorite ? favorite.length : 0;

  const renderComponent = () => {
    switch (selectedComponent) {
      case "contactedProperties":
        return <ContactedProperties refetchSavedProperties={refetchSavedProperties} />;
      case "savedProperties":
        return <SavedProperties refetchSavedProperties={refetchSavedProperties} />;
      case "savedSearches":
        return <SavedSearches refetchSavedProperties={refetchSavedProperties} />;
      default:
        return <UserProfile />;
    }
  };

  return (
    <Flex
  direction={{ base: "column", md: "row" }}
  w="100%"
  p={{ base: 4, md: 8 }}
  bg="white"
  alignItems={{ base: "center", md: "flex-start" }}
  justifyContent="center"
  minH="100vh"
>
  <Box 
    w={{ base: "100%", md: "250px" }}
    bg="white"
    borderRadius="md"
    boxShadow="sm"
    mt={20}
    mb={{ base: 4, md: 0 }}
  >
    <AccountMenu
      onSelect={setSelectedComponent}
      selectedComponent={selectedComponent}
      savedPropertiesCount={savedPropertiesCount}
      contactedPropertiesCount={contactedPropertiesCount}
      savedSearchesCount={savedSearchesCount}
      loading={savedLoading || contactedLoading || loadingSavedSearches}
    />
  </Box>
  <Box 
    flex="1"
    maxW={{ base: "100%", md: "1000px" }}
    bg="white"
    p={{ base: 4, md: 8 }}
    ml={{ base: 0, md: 8 }}
  >
    {renderComponent()}
  </Box>
</Flex>
  );
};

export default Dashboard;
