import { Box, Text, Heading, Button, Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const Header = () => {
  return (
    <Flex
      pr={{ base: 4, md: 2 }}
      pt={{ base: "50px", md: 0 }}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box>
        <Heading as="h2" size="xl" fontWeight="bold" color="black" mb={2}>
          <FormattedMessage id="google_ratings_header" defaultMessage="Google Ratings & Reviews" />
        </Heading>
        <Text fontSize="lg" color="gray.500">
          <FormattedMessage
            id="google_ratings_description"
            defaultMessage="Discover what customers are saying about SandSeekers."
          />
        </Text>
      </Box>
      <a href="https://www.google.com/maps/place/Sand+Seekers+Real+Estate+LLC/@24.4903161,54.3716495,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5e475799bd6d69:0xd3003a730c3cb2b9!8m2!3d24.4903161!4d54.3716495!16s%2Fg%2F11q279rdnb!5m2!1e1!1e4?entry=ttu&g_ep=EgoyMDI1MDEwMS4wIKXMDSoASAFQAw%3D%3D" isExternal>
        <Button
          bg={'white'}
          color={'black'}
          border="1px solid black"
          _hover={{ bg:'gray.100' }}
          size="md"
          mt={{ base: 4, md: 0 }}
          mr={{ base: 0, md: 6 }}
          px={{ base: 6, md: 5 }}
          fontSize={{ base: "sm", md: "md" }}
        >
          <FormattedMessage id="view_google_reviews" defaultMessage="View All" />
        </Button>
      </a>
    </Flex>
  );
};

export default Header;
