import { Card, Carousel, Tooltip } from "antd";
import React, { useState, useEffect, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../utils/Helper";
import { bucket_base_url } from "../../constants/constant";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import { useDebouncedCallback } from "use-debounce";
import { useMutation } from "@tanstack/react-query";
import { disLikeProperty, likeProperty } from "../../api/Property/propertyApi";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import { imageCache } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";

const PropertyCard = ({ item, liked, refetch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state?.user);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const [canAutoplay, setCanAutoplay] = useState(false);
  const images = item?.file;
  const imagesLoadedRef = useRef(0);
  const desiredLoadCount = Math.min(images?.length || 0, 10);

  const { mutate: mutateLike } = useMutation({
    mutationFn: (data) => likeProperty(data),
  });
  const { mutate: mutateDislike } = useMutation({
    mutationFn: () => disLikeProperty(liked[0]?.id),
  });
  const [like, setLike] = useState(liked?.length > 0 ? true : false);
  const handleLike = useDebouncedCallback(() => {
    if (auth) {
      setLike((prev) => !prev);
      if (like) {
        mutateDislike("", {
          onSuccess: () => {
            refetch();
          },
        });
      } else {
        const body = {
          propertyId: item.id,
        };
        mutateLike(body, {
          onSuccess: () => {
            refetch();
          },
        });
      }
    } else {
      dispatch(setLoginModalVisible(true));
    }
  }, 300);

  useEffect(() => {
    imagesLoadedRef.current = 0;
    setCanAutoplay(false);
  }, [item?.id]); 

  useEffect(() => {
    if (!images || images.length === 0) return;

    const preloadImages = () => {
      images?.slice(0, 10).forEach((image) => {
        const imageUrl = image?.filePath?.includes("http")
          ? image.filePath
          : `${bucket_base_url}${image?.filePath}`;

        const handleImageLoad = () => {
          if (!imageCache.has(imageUrl)) {
            imageCache.set(imageUrl, true);
          }
          requestAnimationFrame(() => {
            imagesLoadedRef.current += 1;
            checkIfEnoughLoaded();
          });
        };

        if (!imageCache.has(imageUrl)) {
          const img = new Image();
          img.src = imageUrl;
          img.onload = handleImageLoad;
          img.onerror = handleImageLoad;
        } else {
          handleImageLoad();
        }
      });
    };

    const checkIfEnoughLoaded = () => {
      if (imagesLoadedRef.current >= desiredLoadCount && !canAutoplay) {
        setCanAutoplay(true);
      }
    };

    preloadImages();

    const fallbackTimeout = setTimeout(() => {
      if (!canAutoplay) {
        setCanAutoplay(true);
      }
    }, 60000);

    return () => clearTimeout(fallbackTimeout);
  }, [images, desiredLoadCount, canAutoplay]);

  return (
    <div>
      <Card className="card" >
        <Carousel
         arrows
         dots={false}
         autoplay={canAutoplay}
         autoplaySpeed={6000}
        >
          {images?.map((image, index) => (
            <div className="property-card-image-text-container" key={index}>
              <div className="property-card-image-text">
                <p>{item?.offering_type}</p>
              </div>
              <div className="property-card-like">
                <button
                  className={`like-button ${like ? "liked" : ""}`}
                  onClick={handleLike}
                >
                  {like ? <FaHeart /> : <CiHeart />}
                </button>
              </div>
              <img
                src={
                  image?.filePath?.includes("http")
                    ? image.filePath
                    : `${bucket_base_url}${image?.filePath}`
                }
                alt="propertyImage"
                className="card-image"
                loading={index >= 10 ? "lazy" : "eager"}
              />
            </div>
          ))}
        </Carousel>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/property-details/${item?.id}`, {
              state: profileData,
            })
          }
        >
          <Tooltip title={item?.title_en}>
            <p className="card-state-description">
              {truncateString(item?.title_en, 40)}
            </p>
          </Tooltip>
          <p className="card-state-name">AED {item?.price}</p>
          <div className="card-location-container">
            <img src="/icons/location.svg" alt="locationIcon" />
            <p>
              {item?.community},{item?.city}
            </p>
          </div>
          <div className="property-apartments-detail-container">
            <div className="property-beds-container">
              <img src="/icons/bed.svg" alt="RoomIcon" className="property-icon" />
              <span>{`${item?.bedroom} Beds`}</span>
            </div>
            <div className="property-beds-container">
              <img src="/icons/bath.svg" alt="BathIcon" className="property-icon" />
              <span>{`${item?.bathroom} Baths`}</span>
            </div>
            <div className="property-beds-container">
              <img src="/icons/square.svg" alt="SquareAreaIcon" className="property-icon" />
              <span>{`${item?.size} SQFT`}</span>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default memo(PropertyCard);