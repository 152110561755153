import React, { useEffect, useState } from "react";
import { Box, Grid, Text, Spinner } from "@chakra-ui/react";
import { PropertyCard } from "./Card";
import axios from "axios";
import { base_url } from "../../constants/constant";
import { useQuery } from "@tanstack/react-query";
import { getLikeProperty } from "../../api/Property/propertyApi";
import { FormattedMessage } from "react-intl";

const SavedSearches = ({ refetchSavedProperties }) => {
  const auth = JSON.parse(localStorage.getItem("userData"));
  const [savedSearches, setSavedSearches] = useState([]);
  const [searchResults, setSearchResults] = useState({});
  const [loading, setLoading] = useState(false);

  const { data: favoriteProperties, refetch: refetchLikedProps } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  useEffect(() => {
    const userId = auth?.id;
    
    let userSearches = [];

    if (userId) {
        userSearches = JSON.parse(localStorage.getItem(`savedSearches_${userId}`)) || [];
        setSavedSearches(userSearches);
    }

    const fetchSearchResults = async () => {
      setLoading(true);
      const results = {};

      await Promise.all(
        userSearches.map(async (search, index) => {
          try {
            const response = await axios.get(`${base_url}/property-listings`, {
              params: search,
              headers: { "ngrok-skip-browser-warning": true },
            });
            results[index] = response.data.records;
          } catch (error) {
            console.error("Failed to fetch properties for saved search:", error);
          }
        })
      );

      setSearchResults(results);
      setLoading(false);
    };

    if (userSearches.length > 0) {
      fetchSearchResults();
    }
  }, [auth?.id]);

  const getFavoriteId = (propertyId) => {
    const favorite = favoriteProperties?.find((fav) => fav.Property.id === propertyId);
    return favorite ? [{ id: favorite.id }] : [];
  };

  return (
    <Box 
      p={{ base: 2, md: 4 }}
    >
      <Text 
        fontSize="xl" 
        fontWeight="bold" 
        pt={{ base: 5, md: 10 }}
        textAlign={{ base: "center", md: "left" }}
      >
        <FormattedMessage id="savedSearches.title" defaultMessage="Saved Searches" />
      </Text>
      {loading ? (
        <Spinner />
      ) : savedSearches.length > 0 ? (
        savedSearches.map((search, index) => (
          <Box 
            key={index} 
            mb={{ base: 6, md: 8 }}
          >
            <Grid 
              templateColumns="1fr"
              gap={{ base: 6, md: 4 }}
            >
              {searchResults[index]?.slice(0, 3).map((property) => (
                <PropertyCard
                  key={property.id}
                  item={property}
                  liked={getFavoriteId(property.id)}
                  refetchSavedProperties={refetchSavedProperties}
                  refetch={refetchLikedProps}
                  isSavedSearches={true}
                />
              ))}
            </Grid>
          </Box>
        ))
      ) : (
        <Text textAlign={{ base: "center", md: "left" }}>
          <FormattedMessage id="savedSearches.noSaved" defaultMessage="No saved searches found." />
        </Text>
      )}
    </Box>
  );
};

export default SavedSearches;
