import { Row } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { bucket_base_url } from "../../constants/constant";
import { decodeHtmlEntities } from "../../utils/Helper";

const BlogDetails = () => {
  const { state } = useLocation();

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="blog-details-image">
        <img
          src={`${bucket_base_url}${state?.id?.file[0]?.filePath}`}
          alt="blog"
          className="blog-details-image-2"
          loading="lazy" 
        />
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "81px",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: 1,
          }}
        ></div>
        <Row className="blogTitle">{state?.id?.title}</Row>

        <div
          className="blog-details"
          dangerouslySetInnerHTML={{
            __html: decodeHtmlEntities(state?.id?.content),
          }}
        />
      </div>
    </div>
  );
};

export default BlogDetails;
