import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { Flex, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@chakra-ui/react";

const Carousel = ({ children, cardWidth = 350, cardMargin = 20 }) => {
  const numItems = React.Children.count(children);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalCardWidth = cardWidth + cardMargin;
  const autoplayRef = useRef(null);
  const containerRef = React.createRef();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const visibleDots = 4; 
  let startX = 0;
  let startY = 0;

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const containerWidth = containerRef.current?.offsetWidth || window.innerWidth;
      setContainerWidth(containerWidth);
      const calculatedItemsPerPage = Math.max(
        Math.floor(containerWidth / totalCardWidth),
        1
      );
      setItemsPerPage(calculatedItemsPerPage);
    };

    calculateItemsPerPage();
    window.addEventListener("resize", calculateItemsPerPage);

    return () => window.removeEventListener("resize", calculateItemsPerPage);
  }, [containerRef, totalCardWidth]);

  const totalPages = Math.ceil(numItems / itemsPerPage);

  const clonedChildren = useMemo(() => [
    ...React.Children.toArray(children).slice(-itemsPerPage),
    ...React.Children.toArray(children),
    ...React.Children.toArray(children).slice(0, itemsPerPage),
  ], [children, itemsPerPage]);

  const handleTransitionEnd = () => {
    setIsAnimating(false);
    if (currentPage === 0) {
      setCurrentPage(totalPages);
    } else if (currentPage === totalPages + 1) {
      setCurrentPage(1);
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    startX = touch.clientX;
    startY = touch.clientY;
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;

    const deltaX = endX - startX;
    const deltaY = endY - startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        slide("PREV");
      } else {
        slide("NEXT");
      }
    }
  };

  const logicalPage = useMemo(
    () => (currentPage - 1 + totalPages) % totalPages,
    [currentPage, totalPages]
  );

  const dotsStartIndex = Math.max(
    0,
    Math.min(logicalPage - Math.floor(visibleDots / 2), totalPages - visibleDots)
  );
  const dotsEndIndex = Math.min(totalPages, dotsStartIndex + visibleDots);

  const slide = useCallback(
    (direction) => {
      if (isAnimating) return;
      setIsAnimating(true);
      setCurrentPage((prevPage) => (direction === "NEXT" ? prevPage + 1 : prevPage - 1));
    },
    [isAnimating]
  );

  const goToPage = useCallback((page) => {
    if (isAnimating) return;
    setCurrentPage(page + 1);
  }, [isAnimating]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('NEXT'),
    onSwipedRight: () => slide('PREV'),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
    trackTouch: true,
    delta: 10,
  });

  useEffect(() => {
    autoplayRef.current = setInterval(() => {
      if (!isAnimating) slide("NEXT");
    }, 3000);

    return () => {
      clearInterval(autoplayRef.current);
    };
  }, [slide, isAnimating]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("touchstart", handleTouchStart, { passive: true });
      container.addEventListener("touchend", handleTouchEnd, { passive: true });
    }
  
    return () => {
      if (container) {
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [containerRef]);

  return (
    <div
      {...(isMobile ? handlers : {})}
      ref={containerRef}
      style={{
        position: "relative",
        overflow: "visible",
        maxWidth: "100%",
      }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        overflow="hidden"
        mx="auto"
        maxW="100%"
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
          animate={{ x: -(currentPage * totalCardWidth) }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          onAnimationComplete={handleTransitionEnd}
        >
          {clonedChildren?.map((child, index) => (
            <motion.div
              key={index}
              layout
              style={{
                width: `${cardWidth}px`,
                margin: `${cardMargin / 2}px`,
                flexShrink: 0,
                paddingBottom: "20px"
              }}
            >
              {child}
            </motion.div>
          ))}
        </motion.div>
      </Flex>
      <Flex justifyContent="center" mt={4}>
        {isMobile ? (
          <motion.div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              width: `${visibleDots * 30}px`,
              justifyContent: "center",
            }}
          >
            {Array.from({ length: totalPages }, (_, index) => index)
              .slice(dotsStartIndex, dotsEndIndex)
              .map((dotIndex) => (
                <motion.div
                  key={dotIndex}
                  style={{
                    display: "inline-block",
                    width: logicalPage === dotIndex ? "20px" : "10px",
                    height: "10px",
                    backgroundColor: logicalPage === dotIndex ? "black" : "gray",
                    borderRadius: "5px",
                    margin: "0 5px",
                    transition: "width 0.3s ease-in-out",
                  }}
                ></motion.div>
              ))}
          </motion.div>
        ) : (
          Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              onClick={() => goToPage(index)}
              mx={1}
              borderRadius="50%"
              bg={logicalPage === index ? "black" : "gray.300"}
              _hover={{ bg: "gray.400" }}
              width="8px"
              height="8px"
              minW="8px"
              p={0}
            />
          ))
        )}
      </Flex>
    </div>
  );
};

export default Carousel;
