import React from "react";
import MapProvider from "../../common/MapProvider";
import FullMapView from "../FullMapView";

const FullMapViewWithProvider = (props) => (
  <MapProvider>
    <FullMapView {...props} />
  </MapProvider>
);

export default FullMapViewWithProvider;
